import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/RegisterView.vue')
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPasswordView.vue')
  },
  {
    path: '/confirmEmail',
    name: 'EmailConfirmation',
    component: () => import(/* webpackChunkName: "confirmEmail" */ '../views/EmailConfirmationView.vue')
  },
  {
    path: '/redirect',
    name: 'redirect',
    component: () => import(/* webpackChunkName: "redirect" */ '../views/RedirectView.vue')
  },
  {
    path: '/signin-oidc',
    name: 'signin-oidc',
    component: () => import(/* webpackChunkName: "signinOidc" */ '../views/SignInOidcView.vue')
  },
  {
    path: '/signout-callback-oidc',
    name: 'signout-callback-oidc',
    component: () => import(/* webpackChunkName: "signoutCallbackOidc" */ '../views/SignOutCallbackOidcView.vue')
  },
  {
    path: '/portal',
    name: 'portal',
    component: () => import(/* webpackChunkName: "portal" */ '../views/PortalView.vue'),
    children: [
      {
        path: 'applications',
        component: () => import(/* webpackChunkName: "applications" */ '../views/portal/ApplicationsView.vue'),
      },
      {
        path: 'profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/portal/ProfileView.vue'),
      },
      {
        path: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/portal/SettingsView.vue'),
      },
      {
        path: 'organization',
        component: () => import(/* webpackChunkName: "organization" */ '../views/portal/OrganizationView.vue'),
      },
      {
        path: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ '../views/portal/AdminView.vue'),
        meta: {
          superAdminRequired: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// Check login before opening each route
router.beforeEach(async (to, from, next) => {
  if (to.path === '/'){
    // If a user is already logged in, redirect from login screen to overview, unless there is a query for the login screen (e.g. organizations)
    if (to.query.screen && typeof to.query.screen === 'string') {
      next();
    } else {
      try {
        await store.dispatch('checkLogin');
        if (!store.state.organization) {
          window.location.href = '/?screen=organizations';
        } else {
          window.location.href = '/portal/applications';
        }
      } catch (_){
        next();
      }
    }
  } else if (to.path === '/register' || to.path === '/resetPassword' || to.path === '/confirmEmail'|| to.path === '/redirect' || to.path === '/signin-oidc') {
    next();
  } else {
    try {
      const user = await store.dispatch('checkLogin');
      if (!store.state.organization) {
        window.location.href = '/?screen=organizations';
      } else {
        if (to.meta.superAdminRequired && !user.superAdmin) {
          window.location.href = '/portal/applications';
        } else {
          next();
        }
      }
    } catch (_) {
      if (_.response.status == 401) {
        const pathfiltered = to.path.replace(/[^0-9]/g, '')
        if (pathfiltered.length > 0) {
          window.location.href = `/redirect?orgid=${pathfiltered}`
        } else {
          window.location.href = '/';
        }
      } else {
        window.location.href = '/';
      }
    }
  }
});

export default router;
